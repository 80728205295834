<template>
  <div>
    <h1>
      Пары
    </h1>
    <div class="encounters-list">
      <div v-for="couple in couples" class="encounters-photo">
        <img :src="'https://neegma.com/' + couple.photos[0]" />
        <div class="encounters-controls-wrap">
          <div class="encounters-label">
            <div class="encounters-name">
              {{ couple.name }}, {{ couple.age }}
            </div>
            <div class="encounters-about">
              {{ couple.about }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      couples: []
    }
  },
  methods: {
    async initCouplesList() {
      const token = localStorage.getItem('token')
      if (null === token) {
        throw new Error('Token localStorage param required')
      }

      return await axios
          .get('https://neegma.com/api/getCouplesList', {
            params: {
              token: token,
              order: 'asc'
            }
          })
          .then((response) => {

            const couples = response.data?.couples ?? null

            if (null === couples) {
              throw new Error('Get couples failed')
            }

            this.couples = couples
          })
    }
  },
  mounted() {
    this.initCouplesList()

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        console.log(entry)
        if (!entry.isIntersecting) {
          return;
        }
        this.initCouplesList();
      });
    });

    observer.observe(document.querySelector('div.encounters-list'));
  }
}
</script>

<style scoped>
@import "@/assets/encounters.css";

.encounters-list {
  display: flex;
  flex-direction: column;
  gap: var(--main-blocks-gap);
}
</style>