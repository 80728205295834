<template>
  <nav>
    <ul>
      <li>
        <router-link to="/couples">
          <span class="icon-man-woman"></span>
          <span class="nav-button-label">
            Пары
          </span>
        </router-link>
      </li>
      <li>
        <router-link to="/">
          <span class="icon-heart"></span>
          <span class="nav-button-label">
            Знакомства
          </span>
        </router-link>
      </li>
      <li>
        <router-link to="/profile">
          <span class="icon-user"></span>
          <span class="nav-button-label">
            Профиль
          </span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {

}
</script>

<style scoped>
nav {
  position: fixed;
  z-index: 3;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: env(safe-area-inset-bottom);
  background: rgb(177,81,208);
  background: linear-gradient(90deg, rgba(177,81,208,1) 0%, rgba(14,91,187,1) 100%);
}
nav ul {
  list-style: none;
  padding: 0 var(--main-blocks-gap);
  margin: 0;
  display: flex;
  justify-content: space-between;
  height: var(--nav-height);
}
nav li {
  flex-basis: 80px;
  font-size: 1.2rem;
}
nav a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  text-decoration: none;
  color: #fff;
  height: 100%;
}
.nav-button-label {
  text-transform: uppercase;
  font-size: 0.7rem;
}
</style>