import {createRouter, createWebHistory} from "vue-router";
import EncountersPage from "@/components/EncountersPage/EncountersPage.vue";
import ProfilePage from "@/components/ProfilePage/ProfilePage.vue";
import CouplesPage from "@/components/CouplesPage/CouplesPage.vue";
import SettingsPage from "@/components/SettingsPage/SettingsPage.vue";

const routes = [
    {
        path: '/',
        component: EncountersPage
    },
    {
        path: '/couples',
        component: CouplesPage
    },
    {
        path: '/profile',
        component: ProfilePage
    },
    {
        path: '/settings',
        component: SettingsPage
    }
]

const router = createRouter({
    routes,
    history: createWebHistory()
})

export default router;