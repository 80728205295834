<template>
  <loading-screen v-if="isLoading" :isAuthFailed="isAuthFailed" />
  <div class="page-view" v-show="!isLoading">
    <router-view />
  </div>
  <app-nav v-show="!isLoading" />
</template>

<script>
import AppNav from "@/components/AppNav/AppNav.vue";
import LoadingScreen from "@/components/LoadingScreen/LoadingScreen.vue";
import axios from "axios";

export default {
  components: {
    LoadingScreen,
    AppNav
  },
  data() {
    return {
      token: localStorage.getItem('token'),
      isLoading: true,
      isAuthFailed: false
    }
  },
  methods: {
    async getAuthResponse() {
      const urlParams = new URLSearchParams(window.location.search);
      let token = urlParams.get('token')

      if (null === token) {
        token = localStorage.getItem('token')

        if (null === token) {
          throw new Error('token query param required')
        }
      }

      return await axios
          .get('https://neegma.com/api/getAuthResponse', {
            params: {
              token: token
            }
          })
          .then((response) => {
            const userId = response.data?.user_id ?? null

            if (null === userId) {
              throw new Error('Auth failed')
            }

            localStorage.setItem('token', token)
            return token
          })
    }
  },
  mounted() {
    // Disable out of Telegram using on production
    /*if (process.env.NODE_ENV === 'production' && 'unknown' === window.Telegram.WebApp.platform) {
      alert('Только для TG!');
    }*/

    // Expand WebApp
    window.Telegram.WebApp.expand()

    this.getAuthResponse()
        .then((token) => {
          this.token = token

          setTimeout(() => {
            this.isLoading = false
          }, 2000)
        })
        .catch((error) => {
          this.isAuthFailed = true
          console.log(error)
        })
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');
@import "@/assets/icomoon/style.css";

:root {
  --main-border-radius: 12px;
  --main-blocks-gap: 15px;
  --nav-height: 50px;
}
* {
  box-sizing: border-box;
}
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  color: #000;
  font-size: 16px;
  font-family: 'Roboto', Tahoma, sans-serif;
  background: #fff;
}
button {
  border: 0;
}
#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom);
}
.page-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: var(--main-blocks-gap);
  padding-bottom: calc(var(--main-blocks-gap) + var(--nav-height));
}
</style>