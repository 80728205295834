<template>
  <div class="loading-screen-overlay">
    <div class="loading-screen">
      <div class="loading-spinner" v-if="!isAuthFailed">
        <span class="icon-spinner8"></span>
      </div>
      <div class="loading-text" v-if="!isAuthFailed">
        NeegmaBot
      </div>
      <div class="loading-text" v-if="isAuthFailed">
        Ошибка авторизации
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isAuthFailed: {
      default: false
    }
  }
}
</script>

<style scoped>
.loading-screen-overlay {
  flex-grow: 1;
  color: #fff;
  background: rgb(177,81,208);
  background: linear-gradient(90deg, rgba(177,81,208,1) 0%, rgba(14,91,187,1) 100%);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.loading-screen {
  margin: auto;
  padding-top: var(--nav-height); /* Compensation of nav height */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--main-blocks-gap);
  -moz-animation: screen-fade-in 2s;
  -webkit-animation: screen-fade-in 2s;
  animation: screen-fade-in 2s;
}
.loading-text {
  font-size: 1.5rem;
  font-weight: bold;
}
.loading-spinner span {
  line-height: 1;
  font-size: 72px;
  display: block;
  -moz-animation: loading-spin 1.5s linear infinite;
  -webkit-animation: loading-spin 1.5s linear infinite;
  animation: loading-spin 1.5s linear infinite;
}
@-moz-keyframes loading-spin {
  100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes loading-spin {
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes loading-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes screen-fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@-webkit-keyframes screen-fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes screen-fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
</style>