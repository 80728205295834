<template>
  <div class="encounters-container">
    <div class="encounters">
      <div class="encounters-photo">
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Владимир_Путин_%2818-06-2023%29_%28cropped%29.jpg/360px-Владимир_Путин_%2818-06-2023%29_%28cropped%29.jpg"/>
        <div class="encounters-controls-wrap">
          <div class="encounters-label">
            <div class="encounters-name">
              Владимир, 70
            </div>
            <div class="encounters-about">
              Для друзей Вадим
            </div>
          </div>
          <div class="encounters-controls">
            <button class="encounters-dislike-button">
              👎
            </button>
            <button class="encounters-superlike-button">
              🤩
            </button>
            <button class="encounters-like-button">
              👍
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      userId: localStorage.getItem('tguid')
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
@import "@/assets/encounters.css";

.encounters-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: calc(-1 * var(--main-blocks-gap));
  background: #2a2a2a;
}
.encounters {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  gap: var(--main-blocks-gap);
}
.encounters-controls button {
  height: 60px;
  width: 60px;
  border-radius: 10px;
  font-size: 36px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.encounters-dislike-button {
  background: rgb(14,91,187);
}
.encounters-superlike-button {
  background: rgb(177,81,208);
  background: linear-gradient(90deg, rgb(14,91,187) 0%, rgba(177,81,208) 100%);
}
.encounters-like-button {
  background: rgb(177,81,208);
}
</style>