<template>
  <div>
    <h1>
      Профиль
    </h1>
    <router-link to="/settings">
      Настройки
    </router-link>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>